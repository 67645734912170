<template>
<div>
        <v-app-bar height="100" flat style="
        background: url('https://curubasoft.com/img/milford/bgM.png');" class="hidden-sm-and-up">
            <img src="@/assets/logo.png" width="150" alt="">
                <v-spacer></v-spacer>
                <v-btn icon text style="color: white;border: solid 3px #fff;"
                @click="nav = !nav" class="mr-2"
                >
                <i class="fas fa-bars"></i>
                </v-btn>
        </v-app-bar>

        <v-navigation-drawer v-model="nav" app style="width:100%;background: url('https://curubasoft.com/img/milford/bgM.png');">
        
            <v-app-bar flat style="background: transparent;padding: 20px 0 0 0;">
                <img src="@/assets/logo.png" width="150" alt="">
                <v-spacer></v-spacer>
                <v-btn icon text style="color: white;border: solid 3px #fff;"
                @click="nav = !nav" class="mr-2"
                >
                <i class="fas fa-times"></i>
                </v-btn>
            </v-app-bar>
            <v-list style="padding:20px;margin: 50px auto;display: block;color: #E7E7E7;font-size: 25px;">
                <v-list-item
      v-for="(item, i) in menu" :key="i"
      class="mb-3"
      @click="irPagina(item.link)"
      >

        <v-list-item-title style="font-family: 'Oswald', sans-serif;">{{item.name}}</v-list-item-title>
      </v-list-item>

      <v-list-group
          :value="false"
          no-action
          class="mb-3"
        >
          <template v-slot:activator>
            <v-list-item-content>
                <v-list-item-title style="font-family: 'Oswald', sans-serif;">FOOD & BEVERAGE MENU<i class=" ml-3 fas fa-chevron-down"></i></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(item, i) in Food"
            :key="i"
            link
            @click="irCategoria(item.nombre)"
          >
            <v-list-item-title v-text="item.nombre"></v-list-item-title>
          </v-list-item>
          
        </v-list-group>

        <v-list-group
          :value="false"
          no-action
          class="mb-3"
        >
          <template v-slot:activator>
            <v-list-item-content>
                <v-list-item-title style="font-family: 'Oswald', sans-serif;">CATERING<i class=" ml-3 fas fa-chevron-down"></i></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(item, i) in Catering"
            :key="i"
            link
            @click="irCategoria(item.nombre)"
          >
            <v-list-item-title v-text="item.nombre"></v-list-item-title>
          </v-list-item>
          
        </v-list-group>

      <v-list-item
      class="mb-3"
      @click="irPagina('/contact-us')"
      >

        <v-list-item-title style="font-family: 'Oswald', sans-serif;">CONTACT US</v-list-item-title>
      </v-list-item>
    </v-list>

    <div style="bottom:0;padding: 20px;">
        <div>
            Phone: 001-888-8888
        </div>
        <div>
            Email: hello@milford-track.com
        </div>
        <div style="color: #c5a86a;">
            <i class="fas fa-clock pr-2"></i> 08:30 A.M — 11:00 P.M
        </div>
        <v-btn text icon style="margin:10px 10px 0 0;border:solid 2px #fff;color: white;" v-for="(item, i) in redes" :key="i"
            :href="item.link" target="_blank" small
            >
            <i :class="item.name"></i>
            </v-btn>
    </div>

        </v-navigation-drawer>


        <v-app-bar flat style="background: #000000AB;z-index: 1000;
        font-size: 13px;font-family: 'Cabin', sans-serif;
        color: #E1E1E1;border-bottom: 1px dashed #7f7f7f;" height="40" class="hidden-sm-and-down">
            <div>
                Phone: 001-888-8888
            </div>
            <div style="margin-left: 20px;">
                Email: hello@milford-track.com
            </div>
            <div style="margin-left: 20px;color: #c5a86a;">
                <i class="fas fa-clock pr-2"></i> 08:30 A.M — 11:00 P.M
            </div>
            <v-spacer></v-spacer>
            <v-btn text icon style="margin-right:20px;border:solid 2px #fff;color: white;" v-for="(item, i) in redes" :key="i"
            :href="item.link" target="_blank" x-small
            >
            <i :class="item.name"></i>
            </v-btn>
        </v-app-bar>
        
            <v-img src="@/assets/bg.png" style="margin-top: -40px" height="250" class="hidden-md-and-down">
            <v-app-bar height="100"
        style="background: transparent;margin: 80px auto;display: block;width: 80%;"
        >
        <v-btn text style="
        border-top: dashed 1px #E7E7E7;border-bottom: dashed 1px #E7E7E7;padding: 30px 10px;
        background:transparent;letter-spacing: 0;color: #E7E7E7;font-size: 15px;"
        @click="irPagina('/')"
        >
            OUR HOME
        </v-btn>
        <v-btn text style="
        border-top: dashed 1px #E7E7E7;border-bottom: dashed 1px #E7E7E7;padding: 30px 10px;
        background:transparent;letter-spacing: 0;color: #E7E7E7;font-size: 15px;">
            TODAY'S SPECIALS
        </v-btn>
        <v-btn text style="
        border-top: dashed 1px #E7E7E7;border-bottom: dashed 1px #E7E7E7;padding: 30px 10px;
        background:transparent;letter-spacing: 0;color: #E7E7E7;font-size: 15px;">
            HOLIDAY SPECIALS
        </v-btn>
        <img src="@/assets/logo.png" width="200">
        <v-menu
        open-on-hover
        bottom
        offset-y
        >
        <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs"
            v-on="on"
            style="
        border-top: dashed 1px #E7E7E7;border-bottom: dashed 1px #E7E7E7;padding: 30px 10px;
        background:transparent;letter-spacing: 0;color: #E7E7E7;font-size: 15px;">
            FOOD & BEVERAGE MENU<i class="ml-2 fas fa-chevron-down" style="font-size: 10px;"></i>
        </v-btn>
        </template>

        <v-list dense dark>
            <v-list-item
            v-for="(item, index) in Food"
            :key="index"
            @click="irCategoria(item.nombre)"
            >
            <v-list-item-title>{{ item.nombre }}</v-list-item-title>
            </v-list-item>
        </v-list>
        </v-menu>

        <v-menu
        open-on-hover
        bottom
        offset-y
        >
        <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs"
            v-on="on"
        style="
        border-top: dashed 1px #E7E7E7;border-bottom: dashed 1px #E7E7E7;padding: 30px 10px;
        background:transparent;letter-spacing: 0;color: #E7E7E7;font-size: 15px;">
            CATERING<i class="ml-2 fas fa-chevron-down" style="font-size: 10px;"></i>
        </v-btn>
        </template>

        <v-list dense dark>
            <v-list-item
            v-for="(item, index) in Catering"
            :key="index"
            @click="irCategoria(item.nombre)"
            >
            <v-list-item-title>{{ item.nombre }}</v-list-item-title>
            </v-list-item>
        </v-list>
        </v-menu>
        <v-btn text @click="irPagina('/contact-us')"
        style="
        border-top: dashed 1px #E7E7E7;border-bottom: dashed 1px #E7E7E7;padding: 30px 10px;
        background:transparent;letter-spacing: 0;color: #E7E7E7;font-size: 15px;">
            CONTACT US
        </v-btn>
        </v-app-bar></v-img>

        <v-dialog dark persistent width="600" v-model="dialogSub">
            <v-card flat dark style="border: solid 1px #c5a86a;background: #000000;color: #e7e7e7;">
                <v-btn text icon style="background: #000;color: #e7e7e7;
                top: 10px;left: 10px;border: solid 1px #c5a86a;
                " small @click="dialogSub = false">X</v-btn>
                <v-card-text style="padding:50px">
                    <v-layout row justify-center>
                        <v-flex xs12 md4 class="mb-3" v-for="(item, i) in subCat" :key="i">
                            <div class="kss" @click="irCategoria(item.nombre)">
                                    {{ item.nombre }}
                            </div>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>
</div>
</template>

<style>
.kss {
    border:solid 1px #c5a86a;
                            border-radius: 10px;text-align: center;padding: 10px 15px;
                            width:90%;margin: 0 auto;display: block;cursor: pointer;
}
</style>

<style>
@import url('https://fonts.googleapis.com/css2?family=Cabin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
</style>

<script>
import axios from 'axios'
export default {
    data(){
        return {
            Food: [],Catering: [],
            menu:[
                {name: "OUR HOME", link: '/'},
                {name: "TODAY'S SPECIALS", link: '/today-specials'},
                {name: "HOLIDAY SPECIALS", link: '/holiday-specials'},
            ],
            nav:false,
            redes:[
            {
                    name: 'fab fa-facebook-f',
                    link: ''
                },
                {
                    name: 'fab fa-instagram',
                    link: ''
                },
                {
                    name: 'fab fa-whatsapp',
                    link: ''
                }
            ],subCat:[],dialogSub:false
        }
    },
    methods:{
        irCategoria(a){
            const atri = a
            if (atri == 'BEVERAGES' || atri == 'DESSERTS') {
                this.dialogSub = true
                axios.post("https://milford.curubasoft.com/php/getSpecialSubCategoria.php", {
                    categoria: atri
                }).then(res=>{
                    this.subCat = []
                    this.subCat = res.data
                })
            }
            else {
                window.open(`/Dishes?dishes=${atri}`, "_self")
            }
            
        },
        irPagina(e){
            window.open(e, "_self")
        },
        loadSubCategorias(){
            axios.post("https://milford.curubasoft.com/php/getCategorias.php", {
                categoria: 'Food'
            }).then(res=>{
                this.Food = res.data
                axios.post("https://milford.curubasoft.com/php/getCategorias.php", {
                    categoria: 'Catering'
                }).then(res=>{
                    this.Catering = res.data
                })
            })
        }
    },
    mounted(){
        this.loadSubCategorias()
    }
}
</script>