<template>
  <v-app>
    <Toolbar></Toolbar>
    <v-main>
      <router-view/>
    </v-main>
    <footesr></footesr>
  </v-app>
</template>


<style>
*::selection {
  background: #c5a86a;
  color: #fff;
}
</style>
<script>
import Toolbar from './components/Toolbar.vue'
import footesr from './components/footer.vue'
export default {
  name: 'App',

  data: () => ({
    //
  }),
  components:{
    Toolbar, footesr
  }
};
</script>
