<template>
    <div style="background: #000000e7;">
        <div style="padding:100px 0;width:90%;margin: 0 auto;display: block;">
            <v-layout row justify-center>
                <v-flex xs12 md3>
                    <div style="width:90%;margin: 0 auto;display: block;">
                        <p style="font-size: 21px;font-family: 'Oswald', sans-serif;
                        padding-bottom:20px;margin: 0;color: #c5a86a;">
                            ABOUT US
                        </p>
                        <p style="line-height: 25px;font-size:15px">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                        </p>
                    </div>
                </v-flex>
                <v-flex xs12 md3>
                    <div style="width:90%;margin: 0 auto;display: block;">
                        <p style="font-size: 21px;font-family: 'Oswald', sans-serif;
                        padding-bottom:20px;margin: 0;color: #c5a86a;">
                            CONTACT US
                        </p>
                        <p style="font-size:15px">
                            <i class="fas fa-mail-bulk pr-2" style="color:#c5a86a"></i>
                                    <a style="color:#e7e7e7;text-decoration: none;"
                                    href="mailto:milfordtrack89@gmail.com">milfordtrack89@gmail.com</a> <br><br>
                                    <i class="fas fa-phone-alt pr-2" style="color:#c5a86a"></i>
                                    501-223-2257 - 501-223-2257 <br><br>
                                    <i class="fas fa-map-pin pr-2" style="color:#c5a86a"></i>
                                    10809 Executive Center Dr., Plaza 2, Little Rock, AR 72211.
                        </p>
                    </div>
                </v-flex>
                <v-flex xs12 md3>
                    <div style="width:90%;margin: 0 auto;display: block;">
                        <p style="font-size: 21px;font-family: 'Oswald', sans-serif;
                        padding-bottom:20px;margin: 0;color: #c5a86a;">
                            WORK TIME
                        </p>
                        <p style="font-size:15px;line-height: 25px;">
                            Monday - Friday • • • • • <span style="margin-right:10px;color: #c5a86a">8AM-2PM</span> <br>
                            Saturday • • • • • <span style="margin-right:10px;color: #c5a86a">10AM-5PM</span> <br>
                            Sunday • • • • • <span style="margin-right:10px;color: #c5a86a">12AM-5PM</span> <br>
                        </p>
                    </div>
                </v-flex>
              
            </v-layout>
        </div>
        <p class="text-center white--text" style="background:#1b1b1b;font-size: 10px;">
            2023 Milford Track. All rights reserved. Designed by Somos Div.
        </p>
        <div style="background: #fff;">
            <img src="https://curubasoft.com/img/creamos.png" width="75" style="
            margin: 0 auto;display: block;
            padding: 10px 0;" alt="">
        </div>
    </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
*{
color: #e7e7e7;
}</style>


<script>
export default {
    data(){
        return {

        }
    }
}
</script>