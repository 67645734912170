import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/Dishes',
    name: 'dishes',
    component: () => import('../components/Categorias.vue')
  }
  ,
  {
    path: '/contact-us',
    name: 'contact',
    component: () => import('../components/Contacto.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
